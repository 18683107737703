var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        id: "app-drawer",
        value: _vm.isDrawerOpen,
        app: "",
        floating: "",
        "mobile-breakpoint": _vm.breakPoint,
        width: "220",
      },
      on: {
        input: function (val) {
          return _vm.$emit("update:is-drawer-open", val)
        },
      },
    },
    [
      _c(
        "router-link",
        { attrs: { to: "/" } },
        [
          _c("v-img", {
            attrs: {
              src: _vm.logo,
              height: "64",
              contain: "",
              position: "center",
            },
          }),
        ],
        1
      ),
      _c(
        "v-list",
        { staticClass: "pt-lg-4", attrs: { column: "", shaped: "" } },
        [
          _vm._l(_vm.links, function (link, i) {
            return [
              !link.subLinks
                ? _c(
                    "v-list-item",
                    {
                      key: "menu-" + i,
                      staticClass: "v-list-item",
                      attrs: { to: link.to, "active-class": _vm.color },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(_vm._s(link.icon)),
                          ]),
                        ],
                        1
                      ),
                      _c("v-list-item-title", {
                        domProps: { textContent: _vm._s(link.text) },
                      }),
                    ],
                    1
                  )
                : _c(
                    "v-list-group",
                    {
                      key: link.text,
                      attrs: { "no-action": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c(
                                  "v-list-item",
                                  { staticClass: "px-0" },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v(_vm._s(link.icon)),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(link.text)),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "appendIcon",
                            fn: function () {
                              return [
                                _c("v-icon", { attrs: { "x-small": "" } }, [
                                  _vm._v("fa fa-chevron-down"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    _vm._l(link.subLinks, function (subLink) {
                      return _c(
                        "v-list-item",
                        {
                          key: subLink.text,
                          staticClass: "pl-6",
                          attrs: { to: subLink.to },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(_vm._s(subLink.icon)),
                              ]),
                            ],
                            1
                          ),
                          _c("v-list-item-title", {
                            domProps: { textContent: _vm._s(subLink.text) },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }