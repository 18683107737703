var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("drawer", {
        attrs: {
          "is-drawer-open": _vm.isDrawerOpen,
          "break-point": _vm.breakPoint,
        },
        on: {
          "update:isDrawerOpen": function ($event) {
            _vm.isDrawerOpen = $event
          },
          "update:is-drawer-open": function ($event) {
            _vm.isDrawerOpen = $event
          },
        },
      }),
      _c(
        "v-app-bar",
        { attrs: { app: "", flat: "", absolute: "", color: _vm.color } },
        [
          _c(
            "v-container",
            { staticClass: "py-0 fill-height", attrs: { fluid: "" } },
            [
              _vm.responsive
                ? _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.isDrawerOpen = !_vm.isDrawerOpen
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-menu")])],
                    1
                  )
                : _vm._e(),
              _c("v-text-field", {
                staticClass: "mr-4 mt-2",
                staticStyle: { "max-width": "250px" },
                attrs: {
                  label: _vm.$t("toolbar.search"),
                  "prepend-inner-icon": "mdi-magnify",
                  "hide-details": "",
                  outlined: "",
                  rounded: "",
                  dense: "",
                },
              }),
              _c("v-spacer"),
              _c(
                "v-toolbar-items",
                [
                  _c(
                    "v-flex",
                    { attrs: { "align-center": "", layout: "", "py-2": "" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            bottom: "",
                            left: "",
                            "offset-y": "",
                            transition: "slide-y-transition",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        directives: [
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple",
                                          },
                                        ],
                                        staticStyle: {
                                          "font-size": "1rem",
                                          "font-weight": "bold",
                                        },
                                        attrs: { icon: "" },
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$i18n.locale) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-list",
                                { attrs: { dense: "" } },
                                _vm._l(_vm.languages, function (language) {
                                  return _c(
                                    "v-list-item",
                                    { key: language },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeLocale(language)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(language))]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          directives: [{ name: "ripple", rawName: "v-ripple" }],
                          attrs: { icon: "" },
                          on: { click: _vm.switchDarkMode },
                        },
                        [
                          this.$vuetify.theme.dark
                            ? _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("fa-sun"),
                              ])
                            : _c(
                                "v-icon",
                                { attrs: { small: "", dense: "" } },
                                [_vm._v("fa-moon")]
                              ),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          directives: [{ name: "ripple", rawName: "v-ripple" }],
                          attrs: { icon: "", to: "/profile" },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("fa-user"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        { attrs: { icon: "" }, on: { click: _vm.logout } },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("fa-power-off"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }