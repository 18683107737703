import { render, staticRenderFns } from "./Main.vue?vue&type=template&id=63155678&scoped=true&"
import script from "./Main.vue?vue&type=script&lang=ts&"
export * from "./Main.vue?vue&type=script&lang=ts&"
import style0 from "./Main.vue?vue&type=style&index=0&id=63155678&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63155678",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VCol,VContainer,VFadeTransition,VMain,VProgressCircular,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63155678')) {
      api.createRecord('63155678', component.options)
    } else {
      api.reload('63155678', component.options)
    }
    module.hot.accept("./Main.vue?vue&type=template&id=63155678&scoped=true&", function () {
      api.rerender('63155678', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/Main.vue"
export default component.exports