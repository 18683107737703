var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      !_vm.appLoadComplete
        ? _c(
            "v-container",
            { attrs: { "fill-height": "", fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          size: 50,
                          color: "primary",
                          indeterminate: "",
                        },
                      }),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" Loading Application ... "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : [
            _c("Snackbar"),
            _vm.$store.state.configUploads.length
              ? _c(
                  "div",
                  { attrs: { id: "configUploads" } },
                  _vm._l(_vm.$store.state.configUploads, function (cu, i) {
                    return _c(
                      "div",
                      { key: i },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.translate(cu.country.name)) +
                            " / " +
                            _vm._s(_vm.translate(cu.sector.name)) +
                            " : " +
                            _vm._s(cu.status) +
                            " "
                        ),
                        cu.status === "uploading"
                          ? _c("v-progress-circular", {
                              attrs: { value: cu.percent },
                            })
                          : _vm._e(),
                        cu.status === "parsing"
                          ? _c("v-progress-circular", {
                              attrs: { indeterminate: "" },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            _c("toolbar"),
            _c(
              "v-main",
              [
                _c(
                  "v-container",
                  { attrs: { fluid: "" } },
                  [
                    _c(
                      "v-fade-transition",
                      { attrs: { mode: "out-in" } },
                      [_c("router-view")],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
      _c("confirm", { ref: "confirm" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }